#sidebar {
    order: -1;
    background-color: lightgray;
    display: flex;
    overflow: hidden;
}

#sidebar {
    transition: all 0.15s ease-in;
}

#sidebar div:first-child {
    flex-grow: 1;
}
