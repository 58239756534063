/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    /* font-size: 16px; */
    /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.no-tailwind * {
    all: revert;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

.hover-color-pink:hover {
    color: #ec008c;
}

a {
    text-decoration: none;
    font-weight: bolder;
}

hr {
    background-color: #ccc;
    height: 1px;
    width: 100%;
    border: none;
    margin: 0;
}

.todo {
    background: yellow;
}

/** Main Layout rules */
#App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* #main {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
} */

#content {
    min-width: 0;
    flex: 1 1 100%;
    padding: 16px;
}

pre.debug {
    background-color: white;
    padding: 12px 12px;
    border: 1px solid #aaa;
    border-radius: 2px;
    max-height: 750px;
    overflow: scroll;
}

input[readonly] {
    background: rgba(0, 0, 0, 0.1);
}
